import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Component for fall-back question types
 * i.e questions that are not open ended multiple choice or multiple select
 * These include end and intro message questions that do not need a response
 * @param question
 * @return {*}
 * @constructor
 */
const FallBack = ({ question }) => {
  // If the question type is of an error, we broadcast this to the parent as well.
  useEffect(() => {
    if (document.referrer !== '' && question?.questionType === 'QuestionError') {
      if (question.questionText?.includes('not active')) {
        window.parent.postMessage(
          {
            message: 'Survey Not Active!',
            status: 400,
            ok: false,
            code: 'SURVEY_INACTIVE',
          },
          document.referrer,
        );
      } else if (question.questionText?.includes('participants limit')) {
        window.parent.postMessage(
          {
            message: 'Participant Limit Reached!',
            status: 400,
            ok: false,
            code: 'PARTICIPANT_LIMIT_EXCEEDED',
          },
          document.referrer,
        );
      } else if (question.questionText?.includes('You have already completed')) {
        window.parent.postMessage(
          {
            message: 'Survey Already Completed!',
            status: 200,
            ok: true,
            code: 'SURVEY_COMPLETED',
          },
          document.referrer,
        );
      } else {
        // This is the fallback for all question errors
        window.parent.postMessage(
          {
            message: 'Question Error',
            status: 400,
            ok: false,
            code: 'QUESTION_ERROR',
          },
          document.referrer,
        );
      }
    }

    // If question text contains //Section//, we hide the question
    if (question.questionText.includes('//Section//') && question.questionType === 'MESSAGE') {
      document.querySelector('.range-score-question').style.display = 'none';
    }
  }, [question]);

  return (
    <div style={{ width: '100%' }}>
      <h3 className="range-score-question">
        <p
          style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{ __html: question.questionText }}
        >
          {/*<strong>{question.questionText}</strong>*/}
        </p>
      </h3>
    </div>
  );
};

FallBack.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default FallBack;
