import React from 'react';
import './npsQuestion.sass';
// import PropTypes from 'prop-types';

/* Component for nps score question type.
Takes in a question and renders radio buttons from 0-10
*/

const NPSQuestion = ({ question, value, onChange }) => {
  function handleScore(event) {
    onChange(
      { value: event.target.value, text: event.target.value },
      question.questionID,
      question.questionLevel,
    );
  }

  return (
    <div className="nps-container">
      <h4 className="range-score-question">
        <p className="question-text" dangerouslySetInnerHTML={{ __html: question.questionText }}>
          {/*{question?.required === true ? (*/}
          {/*  <span className="required-star">*</span>) : (<></>)}*/}
          {/*<strong>{question.questionText}</strong>*/}
        </p>
      </h4>
      <div className="nps-form">
        <ul className="scale-container">
          <div>
            <label className={value === '0' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={0}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>0</span>
            </label>
            <div>😡</div>
          </div>
          <div>
            <label className={value === '1' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={1}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>1</span>
            </label>
            <div>😡</div>
          </div>
          <div>
            <label className={value === '2' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={2}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>2</span>
            </label>
            <div>😡</div>
          </div>

          <div>
            <label className={value === '3' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={3}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>3</span>
            </label>
            <div>😡</div>
          </div>

          <div>
            <label className={value === '4' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={4}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>4</span>
            </label>
            <div>😡</div>
          </div>

          <div>
            <label className={value === '5' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={5}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>5</span>
            </label>
            <div>😡</div>
          </div>

          <div>
            <label className={value === '6' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={6}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>6</span>
            </label>
            <div>😡</div>
          </div>

          <div>
            <label className={value === '7' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={7}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>7</span>
            </label>
            <div>😐</div>
          </div>

          <div>
            <label className={value === '8' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={8}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>8</span>
            </label>
            <div>😐</div>
          </div>

          <div>
            <label className={value === '9' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={9}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>9</span>
            </label>
            <div>😊</div>
          </div>

          <div>
            <label className={value === '10' ? 'active negative-nps' : 'negative-nps'}>
              <input
                type="radio"
                name="NPS"
                value={10}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>10</span>
            </label>
            <div>😊</div>
          </div>
        </ul>
        {question.questionType === 'OPEN_ENDED_RANGE_0_10' ||
        question.questionType === 'RANGE_0_10' ? (
          <div className="scale-indicator">
            <span style={{ color: 'red' }}>Extremely Dissatisfied</span>
            <span style={{ color: 'green' }}>Extremely Satisfied</span>
          </div>
        ) : (
          <div className="scale-indicator">
            <span style={{ color: 'red' }}>Not at all likely</span>
            <span style={{ color: 'green' }}>Extremely likely</span>
          </div>
        )}
      </div>
    </div>
  );
};

// NPSQuestion.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   question: PropTypes.objectOf(PropTypes.any).isRequired,
// };

export default NPSQuestion;
