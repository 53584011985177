import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './web-form.sass';
import Helmet from 'react-helmet';
import { BrowserView, MobileView } from 'react-device-detect';
import CheckCompletedIcon from '../components/assets/check_circle_outline.png';

const SurveySubmitted = ({ message, joinCode }) => {
  // Temporary Fix for Esco
  const escoLink = 'www.excott.com/summit';

  let linkifiedMessage = message.replace(
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
    "<a href='$1' target='_blank' >link</a>",
  );

  if (joinCode === 'W8XKZ4DM') linkifiedMessage = linkifiedMessage.replace('link', escoLink);

  // On this page, we post a message that the survey has completed
  useEffect(() => {
    // This checks whether our app is embedded
    if (document.referrer !== '') {
      window.parent.postMessage(
        { message: 'Survey Completed!', status: 200, ok: true, code: 'SURVEY_COMPLETED' },
        document.referrer,
      );
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>In App Form| Survey submitted</title>
      </Helmet>
      <BrowserView>
        <div className="form-submission-success">
          {/*<h1 className="title">THANK YOU!</h1>*/}
          <img src={CheckCompletedIcon} alt="Survey Completed Icon" width={70} height={70} />
          <h2
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: linkifiedMessage }}
          ></h2>
          <div>
            <a href="https://www.ajua.com" target={'_blank'} rel="noreferrer">
              <img
                src={require('./assets/ajua_logo.png')}
                alt="Ajua Logo"
                className="logo-header"
              />
              <p>Powered by Ajua</p>
            </a>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="form-submission-success mobile">
          {/*<h1 className="title">THANK YOU!</h1>*/}
          <img src={CheckCompletedIcon} alt="Survey Completed Icon" width={70} height={70} />
          <h5
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: linkifiedMessage }}
          ></h5>
          <a href="https://www.ajua.com" target={'_blank'} rel="noreferrer">
            <span>Powered by Ajua</span>
          </a>
        </div>
      </MobileView>
    </>
  );
};

SurveySubmitted.propTypes = {
  message: PropTypes.string.isRequired,
  joinCode: PropTypes.string,
};

export default SurveySubmitted;
